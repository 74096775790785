import { QrcodeStream } from "vue-qrcode-reader";
import '../assets/fullfilment.css';
const AUTO = "auto";
const OFF = "off";

export default {
    components: { QrcodeStream },
    data: ()=>({
        isValid: undefined,
        camera: AUTO,
        result: null,
        dialog: false,
        data: undefined
    }),
    computed: {
        validationPending() {
            return this.isValid === undefined && this.camera === OFF;
        },
        validationSuccess() {
            return this.isValid === true;
        },
        validationFailure() {
            return this.isValid === false;
        }
    },
    methods: {
        async onDecode(content) {
            this.result = "";
            this.data = undefined;
            this.turnCameraOff();
            const validated = this.validateQRCode(content);
            if(!validated){
                await this.timeout(2000);
                this.turnCameraOn();
            }
        },
        turnCameraOn() {
            this.camera = AUTO;
        },
        turnCameraOff() {
            this.camera = OFF;
        },
        timeout(ms) {
            return new Promise(resolve => {
                window.setTimeout(resolve, ms);
            });
        },
        validateQRCode(qrCode){
            throw new Error('Method not implemented');
        },
        resetValidationState() {
            this.isValid = undefined;
        },
        openResultDialog() {
            this.dialog = true;
        },
        closeDialog(){
            this.dialog = false;
            this.result = "";
            this.data = undefined;
            this.isValid =undefined;
        },
    },
    watch: {
        dialog(new_value){
            if(new_value && this.camera === AUTO){
                this.turnCameraOff()
            } else if (!new_value && this.camera === OFF){
                this.turnCameraOn()
            }
        }
    }
}
